import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/review.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`​
Monster Hunter: World/Iceborne and Monster Hunter Generations Ultimate are two of my favorite games of the last three or four years, with hundreds of hours sunk into both across the Switch, PC and PS4. It perhaps goes without saying that I was beyond hyped for the Rise demo. Capcom did an excellent job getting everyone excited with the weapon previews last month (except for Insect Glaive), so how did the demo stack up against my expectations? I'm going to break it down by weapon type and other gameplay areas.
​`}</p>
    <h2>{`Weapons`}</h2>
    <p>{`​
My thoughts on each of the weapons presented in no particular order.`}</p>
    <h3>{`Hunting Horn`}</h3>
    <p>{`​
Getting this one out of the way first because it has received the most hype out of all the weapons for this next generation.`}</p>
    <p>{`​`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "750px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/86d8010ecac8a08796a2003ba9e06ddf/acb04/huntinghorn.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.79536679536679%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgMF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAgP/2gAMAwEAAhADEAAAAWbKyK5HVFL/xAAZEAEBAQEBAQAAAAAAAAAAAAACAQMSBCP/2gAIAQEAAQUCmvEdtaCV9F40V+meZY//xAAXEQEBAQEAAAAAAAAAAAAAAAABAhAR/9oACAEDAQE/AajgOf/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPwGMf//EABoQAQEBAAMBAAAAAAAAAAAAAAEAERIhMXH/2gAIAQEABj8CNLt8t42F8hS//8QAGhAAAwEBAQEAAAAAAAAAAAAAAAERIUFRcf/aAAgBAQABPyFuQaXnKaGS0l4Syxj4T4Ve8JG3yH//2gAMAwEAAgADAAAAEAgf/8QAFhEBAQEAAAAAAAAAAAAAAAAAABFh/9oACAEDAQE/ENNWv//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQIBAT8Q6UtF/8QAHhABAAIDAAIDAAAAAAAAAAAAAQARITFBUWFxkbH/2gAIAQEAAT8QLbCUtwL/AH6jpWMQEU6PcP5kyoPXtnKiYrcHTtfGY6BbstgWTKUeU//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Hunting Horn",
            "title": "Hunting Horn",
            "src": "/static/86d8010ecac8a08796a2003ba9e06ddf/acb04/huntinghorn.jpg",
            "srcSet": ["/static/86d8010ecac8a08796a2003ba9e06ddf/8356d/huntinghorn.jpg 259w", "/static/86d8010ecac8a08796a2003ba9e06ddf/bc760/huntinghorn.jpg 518w", "/static/86d8010ecac8a08796a2003ba9e06ddf/acb04/huntinghorn.jpg 750w"],
            "sizes": "(max-width: 750px) 100vw, 750px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`​
They nailed it. I was never a HH main, but I've dabbled in it a bit from time to time, and I can honestly say this is the best the horn has ever felt. It looks stylish as all get out and the two silkbind moves you get in the demo are fantastic. The new triple note attack thing is awesome too, dealing a bunch of quick damage. Honestly this thing feels like it attacks at least as quick as the SnS from World/Iceborne, while also doing impact damage which means KO's.
​`}</p>
    <p>{`However...`}</p>
    <p>{`​
It's hard to say from the demo, but if the song lists for all the horns are this simplified, I can't say I understand the change to the draw attack. Two notes of the same type and viola. It's so simple why does it matter if the draw attack is always note 1? It'll also take some reprogramming to remember to have to draw then hit another button right away to perform an attack. Oh, and how many particle effects can you put on the screen at once? Try a hunt with four hunting horns and find out. It can make things a little hard to see if you're in handheld mode.`}</p>
    <p>{`​
These really are very small gripes and of all the weapons in the demo, this was by far my favorite.
​`}</p>
    <h3>{`Great Sword`}</h3>
    <p>{`​
My second true love (after switch axe), but what I've hunted with the most in World and Iceborne, and second most in Generations Ultimate. Of all the weapons I've tried so far, this one benefits the most from the wirebug repositioning. Being able to leap up to do a charge attack in the air or dash to the side to get out the way is an amazing change to this weapon. Keeping the move set from World is also awesome, because I vastly prefer it to any of the style movesets in Generations.
​
Maybe its just me, or maybe the loadout we're given has Quick Sheathe, but wow, does this thing sheathe quickly now.
​`}</p>
    <p>{`But...`}</p>
    <p>{`​
The third step of the true charge slash takes `}<em parentName="p">{`forever`}</em>{` to wind up. I don't know if this will change with the focus skill or not, but damn, it's an incredibly noticeable change. At least you automatically gain charge level out of it once it finally winds up which is a nice bonus. Something else I noticed is with the weapon drawn the recovery of a dodge roll seems very laggy/slow. You can't just roll and then roll again. The silkbind attacks we're given also seem very "meh."`}</p>
    <h3>{`Switch Axe`}</h3>
    <p>{`Absolutely loved it. For one this was, to me, the best looking armor set and weapon in the game. Gimme all the Tron gear please and thank you. Secondly, all the good stuff from Iceborne is back too. I'm also a big fan of the changes to savage axe mode, so you get the charge buff here. It incentivizes switching back and forth between the sword and axe a bit more, even if the sword mode still seems to be pumping out more damage than axe mode even though your axe will now also do phial damage if you're fully charged.`}</p>
    <p>{`Both of the hunter arts were incredible too. Giving you the rocksteady effect while dishing out some decent damage, or allowing you to stay in sword mode longer are both really good options. I doubt we'll see anything like the Generations version of Demon Riot/Energy Charge but getting something like Tempest Axe would be nice, as it would give you another good reason to stay in axe mode. The only thing I might like to see would be something like the old aerial style, where landing on the monster does damage followed another big hit.
​`}</p>
    <h3>{`LBG`}</h3>
    <p>{`​
I loved the LBG in World. Super mobile, you get landmines, sticky bombs were overtuned and you could get multiple KO's a fight. LBG in Generations was kinda boring, but very effective, except it was more about pierce ammo there and aerial style was pretty fun if a little spammy like the great sword. So how does it stack up in the demo?
​
Excellent. All of the mobility of World is here. So is your Wyvernblast. Not only that you get the cool parts of aerial style from Generations here with the addition of being able to stick a Wyvernblast onto the monster, ala the wyrmstake blast of the GL. I don't think the LBG they gave us is any good (only rapid normal 1's? come on) but I really have nothing negative to say about the weapon playstyle and either it or HH will be my main when the game launches.`}</p>
    <h3>{`Hammer`}</h3>
    <p>{`While I initially stumbled on this guy, because I was so used to hit the World version where I had to charge the hammer, after watching either a Gaijin Hunter or Arekkz video and being set straight, I absolutely loved this thing. The spinning slam move from Iceborne is back AND I can do it anytime I want? Yes please! I wasn't a huge fan of the damage output of the massive ground pound silkbind move, especially given the wind up time, but at least it looks incredibly epic.`}</p>
    <p>{`Valor hammer is my favorite style in Generations so the blue stance felt very good to me. If you liked hammer at all, you were probably not disappointed by anything you saw here. The Ludroth hammer did look a little silly though.
​`}</p>
    <h3>{`Gunlance`}</h3>
    <p>{`​
A weapon I really liked in World but never mained, and really couldn't stand in Generations because none of the styles had everything I wanted. It seems like we have the moveset from World here and we're given a normal shelling lance for that full burst playstyle, both of which I consider very good things. The uppercut, reload, ground pound silkbind move is awesome, and getting a big shield on wyrmfire is a nice bonus. All in all, a pretty solid choice if you liked the gunlance from World.`}</p>
    <h3>{`Insect Glaive`}</h3>
    <p>{`Moving on...`}</p>
    <h3>{`Lance`}</h3>
    <p>{`This thing feels just as good here as it did in World/Iceborne. I will miss the clutch counter immensely however. One of the silkbind attacks was a nerfed version of Enraged Guard from Generations. It's fantastic but last for a very short while. The other silkbind attack, I have no idea why you would ever use. Maybe I'm missing something but tethering yourself to the monster so you can jump back to it if it jumps away is kind of meh. Especially given that lance really never had any trouble closing with a monster to begin with, between the charge and the advancing move with your shield or the poke with the lance.`}</p>
    <p>{`Still, no complaints about the weapon itself. It would be amazing if the lance gets some form of absolute evasion lancing from Generations because that was always super fun.`}</p>
    <h3>{`Longsword`}</h3>
    <p>{`I've never really gotten into longsword. It always just sort of bounced off of me, despite looking really cool. However, maybe just maybe, this is the game I'll give it a real go. This is in no small part thanks to moving the helm splitter move to a silkbind attack. It also doesn't hurt that this thing was just putting out absurd numbers of damage, and had the quickest kill times of any weapon in the demo. Having access to another counter from the second silkbind was also very nice. All in all this was the first time I felt like I understood what the weapon was doing and how it was supposed to work and I can't wait to trip up all my fellow hunters with it.`}</p>
    <h3>{`Sword and shield`}</h3>
    <p>{`I played a lot of SnS in Generations where all of it's hunter arts were just incredible and when paired with the Elderfrost Gammoth sword you'd put out insane amounts of uninterruptible damage thanks to round slash being charged all the time, and chaos oil...'nuff said. Well SnS has a new round slash in one of its silkbind moves, it got guard points and it continues to feel very fast and fluid. I couldn't quite make the adjustment to using the perfect rush in Iceborne and the weapon just fell by the wayside for me, and they really seem to have doubled down on perfect rush this game giving it even more combo potential. This "beginner" weapon continues to get fine tuned and have an enormous skill ceiling. If you liked SnS in Iceborne, I think you'll be very happy here.`}</p>
    <h3>{`Bow`}</h3>
    <p>{`I don't have much to say here. It's the bow you know and love from World/Iceborne but with another powershot thrown in. I suck at the bow in every incarnation I've ever played. I tried it a few times here and got similar results. The armor set looks really nice though.`}</p>
    <h3>{`Heavy Bowgun`}</h3>
    <p>{`I really can't bring myself to like this weapon. Nothing I tried in Generations seemed to work, even the overpowered valor mode. It got a little better with the overtuned Loyal Thunder in Iceborne. But it's just not a very fun weapon for me. It definitely got big boost from the wirebug repositioning, though. Maybe by the next game it'll have enough new stuff for me to really sink my teeth in.`}</p>
    <h3>{`Charge Blade`}</h3>
    <p>{`If I could learn one weapon, and learn it well, it would be this guy. In the hands of a capable hunter, you see some truly awesome stuff come out of this. This version is like the World/Iceborne version but suped up a bit. The new guard silkbind move makes it a bit easier on those of us who can't really get the timing of guard points down. The new silkbind move we saw in the latest rampage mode trailer looks in-freaking-credible. I suspect I won't be able to put in the time on this guy again, simply because most my favorite weapons also look to be consuming my time, but in the demo it looked great and felt just like what I remember from World.`}</p>
    <h3>{`Dual Blades`}</h3>
    <p>{`The award for the second best looking set in the demo goes too...the dual blades. I've only really dabbled in these things but they felt really awesome here. I love the new kunai stab silkbind move. And the numbers...so many numbers on the screen.
​`}</p>
    <h2>{`Game Systems`}</h2>
    <p>{`​`}</p>
    <h3>{`Wirebugs`}</h3>
    <p>{`​
It took a little time for these guys to grow on me, but they did. While using them as your "hunter arts" was cool right from the beginning, the traversal options they allow for, and the in combat, non-silkbind mobility is awesome, particularly for the clunkier weapons like the great sword and heavy bowgun. For traversal I recommend always using the ZR option to jump where you're looking because the jump straight, and jump up (in the direction you're facing) always seem to burn me.
​`}</p>
    <h3>{`Palamutes`}</h3>
    <p>{`These guys are...OK. Their biggest selling point is the speed that they allow you pursue the monster, while still eating or sharpening​ your weapon. After the demo, they seem objectively better than the palicos, especially since the heals the palicos were dropping were not efficient and always seemed to be in bubble form. Even in Generations or World the palico had a heal you didn't need to specifically attack/interact with in order to get.`}</p>
    <p>{`In terms of combat use, it is cool to see these guys flinging kunais around on a whip, but the damage (as always seems to be the case with your animal friends) seems inconsequential. Their primary benefit is providing the monster another target so you can either go crazy on the monster or heal up.
​`}</p>
    <h3>{`Wyvern Riding`}</h3>
    <p>{`​
Here's the only change I'm not super convinced of at this point. I'm a big fan of the old mounting mechanic, and I especially liked it in World when each weapon got a unique finisher that did big damage at the end of it. The new system just doesn't feel right to me. `}</p>
    <p>{`For one, the mounting thresholds for the target monster seem to be incredibly high. This is undoubtedly because of the existence of the wirebug and so this makes sense, even if it is a tad disappointing. That said for any monster that isn't the target monster, in the demo at least, it looks like you can sneeze on them and begin the mount. This incentivizes running in the opposite direction and grabbing one of the other 2-3 monsters that inhabit the map, jumping on them and then riding them back to the monster you're actually hunting. Only problem is, this ends with two monsters in the same area which is usually an issue. Dung bombs better be easier to come by.`}</p>
    <p>{`Another thing I had trouble with was, assuming I mounted the target monster all alone, it seemed mostly random how many time I was able to just bang them into the wall before they fell over. Sometimes it was as many as three, other times it was only once.`}</p>
    <p>{`Finally the biggest issue in my mind, is that it's 100% not optional, if a monster is in a mountable state and that true charge slash hits, there goes all your damage cuz it's time to jump on. Oh and once you're on, at the monsters in the demo are anything but agile. It seemed to me that once we closed in another monster dodging was the only way to turn, because these things do `}<em parentName="p">{`not`}</em>{` turn on a dime.`}</p>
    <p>{`Looking at the new rampage mode, it seems obvious that that is why this new system exists. Large sieges with multiple monsters requires new ways to damage them, I just hope the rampage mode isn't required for a huge number of quests.
​`}</p>
    <h2>{`Overall Thoughts`}</h2>
    <p>{`​
The demo has gotten me super excited about the game at the end of March. Every weapon feels great to use, even the ones I almost never use. My one fear is the prevalence of wyvern riding. Hunter Arts being back is amazing, most of the arts we got in the demo were pretty awesome with a few notable exceptions (sorry IG, sorry lance).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      